.text-points--counter {
  position: relative;

  @include breakpoint(md) {
    &:after {
      content: "";
      position: absolute;
      top: calc(2.5rem - 1px);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--color-contrast-low);
      z-index: -1;
    }
  }

  .text-component {
    position: relative;

    p {
      --component-body-line-height: 1.4;
    }

    > i {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-primary);
      background-color: var(--color-white);
      width: 5rem;
      height: 5rem;
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.16),
        0 0 0 0.875rem var(--color-white);
      border-radius: 100rem;
      font-size: 2rem;
      margin-bottom: var(--space-sm);
      transition: box-shadow 0.36s;
      z-index: 2;

      &.done {
        color: var(--color-white);
        background-color: var(--color-primary);

        &:before {
          content: none;
        }
      }
    }

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: calc(2.5rem - 1px);
    //   left: calc(50% + 3.5rem);
    //   width: calc(100% - 7rem);
    //   height: 2px;
    //   background-color: var(--color-contrast-low);
    // }

    @include breakpoint(md) {
      &:hover {
        > i {
          box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 0, 0.1),
            0 0 0 2.25rem var(--color-white);
        }
      }
    }
  }

  .text-points__item {
    position: relative;
    color: var(--color-black);

    .text-component {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    & + .text-points__item {
      margin-top: var(--space-xxl);

      &:before {
        content: "";
        position: absolute;
        bottom: calc(100% + 1em);
        left: calc(50% - 1px);
        width: 2px;
        height: calc(var(--space-xxl) - 2em);
        background-color: var(--color-contrast-low);
      }

      @include breakpoint(md) {
        margin-top: 0;

        &:before {
          content: none;
        }
      }
    }

    @include breakpoint(md) {
      &:first-child,
      &:last-child {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          background-color: var(--color-white);
        }
      }

      &:last-child {
        &:before {
          left: 50%;
        }
      }
    }
  }
}
