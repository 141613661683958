.price {
  display: inline-block;

  del {
    text-decoration-color: var(--c-primary);
  }

  .price__tag {
    background: var(--c-primary);
    padding: 0.25rem 0.75rem;
    border-radius: 100px;
  }
}

.price--vertical {
  display: flex;
  align-items: center;
  font-size: 2rem;

  .c-primary {
    font-weight: 500;
  }

  > * + * {
    margin-left: 1.5rem;
  }
}
