.headers {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 50;

  .sub-header,
  .f-header {
    padding: var(--space-md) 0;
    color: var(--color-white);
    height: auto;

    a {
      color: var(--color-white);
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }

    .site-logo {
      svg {
        path {
          fill: var(--color-white);
        }
      }

      > span {
        color: var(--color-white);
        opacity: 1;

        &:before {
          background-color: var(--color-white);
          opacity: 0.3;
        }
      }
    }
  }
}

.headers {
  header {
    a.header-date {
      background: #282c2a;
      padding: .2rem 1rem;
      color: #fff;
      border-radius: .25rem;
      font-family: var(--font-header);
      font-weight: 400;
      margin-top: -.5rem;
      margin-left: 2rem;
      box-shadow: 0.375rem 0.375rem 0 0 var(--color-primary);
      transition: all .2s;
      opacity: 1 !important;
      text-decoration: none;
    
      @media (min-width: 456px) {
        padding: .2rem 2rem;
      }
    
      @media (min-width: 400px) {
        margin-left: auto;
      }
    
      &:hover {
        color: #000;
        background-color: var(--color-primary);
        box-shadow: 0 0 0 0 var(--color-primary);
      }
    }
  }
}

.f-header {
  .f-header__nav {
    .f-header__nav-grid {
      @include breakpoint(md) {
        align-items: baseline;
      }
    }
  }

  .anim-menu-btn {
    color: var(--color-white);
  }

  .f-header__content {
    display: flex;
  }

  .f-header__nav {
    &.f-header__nav--is-visible {
      top: calc(var(--f-header-height) + var(--space-xxs));
      padding: var(--space-md) 0 var(--space-md);
      // background-color: #1a2843;

      .f-header__list {
        li {
          &:last-of-type {
            border-bottom: 0;
          }
          > a {
            color: #000;
          }
        }
      }
    }
  }
}

.sub-header {
  // margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  @include breakpoint(md) {
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
  }

  .sub-header__right {
    display: flex;
    justify-content: flex-end;
  }

  & + .f-header {
  }
}
