.card {
  &[class*="grid-gap"] {
    margin-bottom: 0;
  }

  &--primary {
    .card__item {
      background-color: var(--color-primary);
      color: var(--color-black);
    }
  }
}

.card__item {
  // --text-lg: 1.2em;

  position: relative;
  padding: var(--space-md) var(--space-lg);
  box-shadow: 0 0.5rem 2rem rgba(248, 246, 246, 0.14);
  border-radius: 0.25rem;
  overflow: hidden;

  @include breakpoint(xs) {
    padding: var(--space-lg) var(--space-xl);
  }

  h4 {
    position: relative;
    z-index: 2;
    color: var(--color-black);
  }

  .card__image-bg {
    position: absolute;
    bottom: -0.5rem;
    right: 0.5rem;
    z-index: 0;
    width: 30%;

    @include breakpoint(xs) {
      bottom: -1.5rem;
    }

    svg {
      max-width: 100%;
      height: auto;

      path {
        fill: var(--color-primary-lighter);
      }
    }
  }
}

.cards-what-awaits {
  --text-md: 1.2em;

  .card__item {
    padding-right: 15%;
    min-height: 200px;
  }
}

.card__image-bg {
}

.company-card {
}

.company-card__image {
  margin-bottom: 2rem;
  height: 3rem;

  svg {
    max-width: 10rem;
    height: auto;
  }
}

.company-card__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .company-card {
    width: 100%;

    @include breakpoint(md) {
      width: calc(33% - 3rem);
    }
  }
}

.company-card {
  display: flex;
  flex-flow: column nowrap;

  h3 {
    display: none;
    margin-bottom: 1em;
    font-size: 1.25rem;
    color: var(--color-contrast-low);
    text-transform: uppercase;
    letter-spacing: 0.01em;
  }

  p {
    line-height: 1.3;
    color: var(--color-contrast-medium);
    margin-bottom: 0.5em;
  }

  .link__wrapper {
    margin-top: auto;

    a {
      text-decoration: none;
    }
  }

  .company-card__image {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 2rem 2rem;
    height: 7rem;
    transform: translateX(0);

    @include breakpoint(md) {
      transform: translateX(-2rem);
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: var(--color-white);
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
      z-index: -1;
      // border-radius: 0.25rem;
    }

    &:before {
      content: "";
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      z-index: -2;

      @include breakpoint(md) {
        left: 1rem;
      }
      // border-radius: 0.25rem;
    }

    svg {
      max-width: 10rem;
      height: auto;
    }
  }

  &--astral {
  }
  &--positive-technologies {
    .company-card__image {
      svg {
        max-width: 15rem;
      }
    }
  }
  &--usergate {
  }
}
