body {
  --form-control-radius: 4rem;
  --btn-radius: 4rem;

  --color-contrast-lower: rgba(255, 255, 255, 0.1);
  --color-contrast-medium: rgba(255, 255, 255, 0.6);

  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  background-color: #101413;
  color: var(--color-white);

  .body-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    &:before {
      content: "";
      position: absolute;
      top: -650px;
      left: calc(50% - 560px);
      width: 2000px;
      height: 2000px;
      background-image: url(../assets/bg-body2.svg);
      background-repeat: no-repeat;
      background-size: cover;
      // z-index: -1;

      @media (max-width: 1280px) {
        left: calc(50% - 700px);
        width: 1900px;
        height: 1900px;
      }
    }
  }

  h1 {
    font-size: var(--text-xxl);
  }
  h2 {
    font-size: var(--text-xl);
  }
  h3 {
    font-size: var(--text-lg);
  }

  @include breakpoint(md) {
    h1 {
      font-size: var(--text-xxxl);
    }
    h2 {
      font-size: var(--text-xxl);
    }
    h3 {
      font-size: var(--text-xl);
    }
  }

  @media (max-width: 500px) {
    h1,
    h2 {
      font-size: var(--text-lg);
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-headers, rgba(255, 255, 255, 1));
  }
}

section {
  a {
    text-decoration: none;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);

    &:hover {
      opacity: 0.7;
    }
  }
}

.section--start-screen {
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  color: var(--color-white);
  padding-top: calc(var(--space-xxl) + 40px);

  a {
    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-white);
  }

  h1 {
    margin-top: 0.5rem;
    margin-bottom: 1 em;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  & + .section--page {
    padding-top: 0;
  }
}

.section--page,
.section--page-gray {
  background-color: transparent;
  padding: var(--space-xl) 0;

  .container {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;

    small {
      display: inline-block;
    }

    > h2,
    > h3,
    > h4 {
      max-width: var(--max-width-xs);
      // margin-bottom: var(--space-lg);
      text-align: center;
    }

    .sub-title {
      max-width: var(--max-width-xs);
      text-align: center;

      &--wide {
        // line-height: 1.3;
        font-size: var(--text-sm);
        max-width: var(--max-width-md);
      }
    }

    .table {
      width: 100%;

      tbody {
        tr {
          td {
            border-bottom: 1px solid var(--color-contrast-lower);
            padding-top: 1rem;
            padding-bottom: 1rem;
          }

          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .additional-prices {
      width: 100%;
      max-width: var(--max-width-md);
      padding: 0 var(--space-lg) 0;

      @include breakpoint(md) {
        padding: 0;
      }
    }

    .text-points {
      width: 100%;
    }

    #russian-map {
      svg {
        height: auto;
      }
    }

    .about-points {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: center;

      span {
        font-size: 2.5rem;
        color: var(--color-primary);
      }
    }

    .clients {
      // text-align: center;
    }

    .sign-up-form {
      --form-control-radius: 0.25rem;
      --form-control-padding-x: 1.5rem;

      max-width: var(--max-width-xxs);
      width: 100%;

      .form-message {
        .help-block {
          padding: 1rem 1.5rem;
          background: #fff;
          border-radius: 0.5rem;
          font-size: 1rem;
          border: 1px solid;

          &.error {
            color: var(--color-error);
          }
          &.success {
            color: var(--color-success);
          }
        }
      }
    }

    .russian-map {
      svg {
        path {
          transition: fill 0.2s;
        }
      }
    }
  }

  .partneers {
    text-align: center;

    svg {
      max-width: 200px;
      height: auto;
    }

    .col-4\@md {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .alert {
    padding: 1rem;
    border-radius: 0.25rem;

    background-color: var(--color-primary-dark);
    color: var(--color-white);
    width: 100%;
    text-align: center;

    margin-bottom: calc(var(--space-xl) * -1);
  }
}

.section--page + .section--page {
  border-top: 1px solid var(--color-contrast-lower);
}

.section--page-gray {
  background-color: var(--color-contrast-lower);
}

.section--primary {
  background-color: var(--color-primary);
  color: var(--color-black);
}

.section--gray {
  background-color: #272b2a;
  color: var(--color-white);
}

footer,
.footer {
  padding: var(--space-lg) 0;
  // background-color: #f8f8f8;
  text-align: center;
}

.footer__bar {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  .site-logo {
    margin-bottom: var(--space-lg);
  }

  @include breakpoint(md) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .site-logo {
      margin-bottom: 0;
    }
  }
}

.arrow-down {
  display: flex;
  justify-content: center;
  animation-duration: 1s;
  animation-name: slideArrow;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
}

@keyframes slideArrow {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(0.375rem);
    transform: translateY(0.375rem);
  }
}
