.contact-phone {
  a {
    display: flex;
    align-items: center;
    font-weight: 700;
    text-decoration-style: dashed;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
      text-decoration-style: dashed;
    }
  }

  .contact-phone__icon {
    font-size: 0;
    margin-left: 0.5rem;
  }
}
