.speaker__wrapper {
}

.speaker {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;

  @include breakpoint(md) {
    flex-flow: row nowrap;
  }
}
.speaker__info {
  position: relative;
  text-align: center;
  min-width: 28%;
  background-color: #272b2a;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @include breakpoint(md) {
    padding: 3rem;
  }
}

.speaker__ava {
  margin-bottom: 1rem;
  max-width: 65%;
}

.speaker__name {
  font-size: var(--text-lg);
  margin-bottom: 0.5em;
  color: var(--color-primary);
}

.speaker__position {
  font-size: 1rem;
}

.speaker__theme-wrapper {
  display: flex;
  flex-flow: column nowrap;
}
.speaker__theme {
  font-size: 1.25rem;
  line-height: 1.4;
  margin: 1rem 0;
  padding: 0 1rem;
  font-weight: 600;
  // margin-left: 4rem;
  border-right: 4px solid var(--color-primary);

  p {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1em;
    opacity: 0.8;
  }

  @include breakpoint(md) {
    font-size: 1.625rem;
    padding: 0 3rem;

    p {
      font-size: 1.125rem;
    }
  }
}
