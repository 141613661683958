.site-logo {
  display: flex;
  align-items: center;

  > svg {
    min-width: 120px;
    height: auto;

    @include breakpoint(xs) {
      min-width: 280px;
    }
  }

  > span {
    position: relative;
    margin-left: 0.65rem;
    padding-left: 0.65rem;

    line-height: 1.412;
    white-space: nowrap;
    font-size: 0.625rem;
    color: #172a4d;
    opacity: 0.65;

    @include breakpoint(xs) {
      margin-left: 1rem;
      padding-left: 1rem;
      font-size: 1.0625rem;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -4px;
      width: 1px;
      height: calc(100% + 8px);
      background-color: #172a4d;
      opacity: 0.16;
    }
  }
}
